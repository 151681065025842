import pixelpin from '../static/images/logos/pixelpin.svg';
import autistica from '../static/images/logos/autistica.png';
import preview from '../static/images/preview.jpg';
import moshi_cover from '../static/images/studies/moshi.png';
import pixel_cover from '../static/images/studies/pixelpin.png';
import nkbm_cover from '../static/images/studies/nkbm.png';
import baobab_cover from '../static/images/case-studies/web_baobab-min.png';
import autistica_cover from '../static/images/case-studies/web_autistica_case-min.png';
import sideways from '../static/images/case-studies/sideways_lrsystem.png';
import ecotrail from '../static/images/case-studies/ecotrail.png';
import mbank from '../static/images/case-studies/mBank.png';
import test from '../static/images/case-studies/Test.png';
import takk1 from '../static/images/case-studies/TakkScroll1.png';
import takk2 from '../static/images/case-studies/TakkScroll2.png';
import takk3 from '../static/images/case-studies/TakkScroll3.png';
import takk4 from '../static/images/case-studies/TakkScroll4.png';
import new_nkbmlogo from '../static/images/logos/nkbmLogo3.png';

import luka_cover from '../static/images/people/luka.png';

export const SOCIAL_URLS = {
    instagram: 'https://www.instagram.com/equaleyes_eq/',
    facebook: 'https://www.facebook.com/EqualeyesSolutions/',
    twitter: 'https://twitter.com/equaleyesltd',
    linkedin: 'https://www.linkedin.com/company/equaleyes-solutions-ltd-/',
    github: 'https://github.com/equaleyes',
};

export const COMPANY_COLORS = {
    nkbm: 'nkbm_red',
    rockwallet: 'rock_green',
    complex: 'complex_gray',
    cbdc: 'cbdc_gray',
    otp: 'otp_green',
    takk: 'takk_black',
    libraro: 'libraro_orange',
    takk_yellow: 'takk_yellow',
};

export const PRIMARY_BG_COLOR = '#101520';
export const SECONDARY_BG_COLOR = '#011640';
export const PRIMARY_TEXT_COLOR = '#EBEBEB';
export const PRIMARY_TITLE_COLOR = '#353535';
export const SECONDARY_TITLE_COLOR = '#7a7a7a';
export const PRIMARY_BUTTON_COLOR = '#E5E22E';
export const PRIMARY_SUBTITLE_COLOR = '#E5E22E';
export const UNDERLINE_COLOR = '#fff3';
export const EE_LIGHT_BLUE = '#B6D6F2';
export const SECONDARY_BUTTON_HOVER = '#2E3138';
export const EE_YELLOW = '#FFDA29';
export const PRIMARY_BUTTON_ACTIVE = '#8F96A3';
export const GRAYSCALE_100 = '#E3E5E8';

export const NAME_COLORS = {
    color_unnamed_1: '#004860',
    color_unnamed_2: '#FFFFFF',
};

export const STUDY_COLORS = {
    color_1: '#C2002E',
};

export const BACKGROUND_COLORS = {
    color_bg_unnamed_1: '#1880c8',
    color_bg_unnamed_2: '#28265a',
    color_bg_unnamed_3: '#a41538',
    color_bg_unnamed_4: '#DD974A',
    color_bg_unnamed_5: '#354DA3',
    color_bg_unnamed_6: '#CCD4FF',
    color_bg_unnamed_7: '#F2F6FA',
    color_bg_unnamed_8: '#D9D9D9',
    color_bg_light: '#FFF',
};

export const FEATURE_COLORS = {
    shadow: 'rgba(0, 0, 0, 0.08)',
    shadow2: 'rgba(0, 0, 0, 0.05)',
    shadow3: 'rgba(16, 21, 32, 0.08)',
    nkbm_red: '#C2002E',
    nkbm_red2: '#A41538',
    rock_green: '#6EAEA1',
    complex_gray: '#454A54',
    complex_black: '#191919',
    cbdc_gray: '#004860',
    otp_green: '#52AE30',
    takk_black: '#081F2C',
    libraro_orange: '#BF6444',
    takk_yellow: '#FFDA29',
    red_shadow: 'rgba(194, 0, 46, 0.2)',
    green_shadow: 'rgba(110, 174, 161, 0.20)',
};

export const FEATURE_SHADOWS = {
    nkbm_red: 'rgba(194, 0, 46, 0.2)',
    rock_green: 'rgba(110, 174, 161, 0.20)',
};

export const TEXT_FONT = 'Lora, serif';
export const TITLE_FONT = 'Futura, sans-serif';

export const TESTIMONIES = [
    {
        text: 'They’ve developed a beautiful product that we’re all proud of, so we’re really happy.',
        title: 'Content Strategist, Autistica',
        name: 'Nicola O’Connor',
        image: autistica,
        sourceHref: 'https://clutch.co/profile/equaleyes-solutions#review-549412',
    },
    {
        text: 'We recommend the team to anyone in need of fast, brilliant, and agile software development.',
        title: 'Head of Digital Banking, Nova KBM d.d.',
        name: 'Tolga Yurteri',
        sourceHref: 'https://clutch.co/profile/equaleyes-solutions#review-629179',
        image: new_nkbmlogo,
    },
    {
        text: "They’re specialist mobile developers, so they really know what they're doing.",
        title: 'CTO, PixelPin',
        name: 'Luke Briner',
        image: pixelpin,
        sourceHref: 'https://clutch.co/profile/equaleyes-solutions#review-226994',
    },
];

export const CASE_STUDIES = [
    {
        title: ['App development.'],
        name: 'Moshi twilight.',
        description:
            'We worked alongside the Mind Candy team to improve the bedtime experience both for children and parents, by bringing innovation to Mind Candy’s sleep stories product. We plugged the gap in their native iOS development resources and our collaboration resulted in the successful delivery and publishing of the Moshi Twilight app - on time and on budget.',
        image: moshi_cover,
        color: BACKGROUND_COLORS.color_bg_unnamed_1,
        headline: 'Mobile development for Mind Candy Ltd.',
    },
    {
        title: ['App development.'],
        description:
            'We developed native Android and iOS apps for showcasing their solution and designed a robust high level architecture for an SDK that makes integration on any mobile platform effortless and secure. We worked closely with PixelPin’s technology leaders, helping them meet their milestones and push their product further along the path to success.',
        clutch: 'https://clutch.co/profile/equaleyes-solutions#review-226994',
        image: pixel_cover,
        color: BACKGROUND_COLORS.color_bg_unnamed_2,
        name: 'PixelPin.',
    },
    {
        title: ['App development,', 'UX/UI design.'],
        description:
            'Equaleyes Solutions Ltd. rebuilt a mobile banking app on iOS and Android. The team fixed bugs, incorporated new features, and updated the UI/UX. They also provided strategic advice and created promo materials.',
        clutch: 'https://clutch.co/profile/equaleyes-solutions#review-629179',
        image: nkbm_cover,
        color: BACKGROUND_COLORS.color_bg_unnamed_3,
        name: 'mBank@Net.',
    },
    {
        title: ['Backend development,', 'App development.'],
        description:
            'After the initial consultancy was carried out, Equaleyes started acting as Baobab’s tech partner  - we built a new native Android Afya Pap app, as well as a web-based CMS for managing in-app content, while using Amazon Web Services as a backbone.\n' +
            '\n' +
            'All of that contributed to the Afya Pap app’s performance to be better and more stable than ever.',
        image: baobab_cover,
        color: BACKGROUND_COLORS.color_bg_unnamed_4,
        name: 'Afya Pap.',
    },
    {
        title: ['Backend development,', 'App development.'],
        description:
            'Molehill Mountain app was made with  autism research charity Autistica and King’s College London.\n' +
            '\n' +
            'We developed easy-to use, engaging, relevant and secure native iOS and Android app.',
        clutch: 'https://clutch.co/profile/equaleyes-solutions#review-549412',
        image: autistica_cover,
        name: 'Autistica.',
        color: BACKGROUND_COLORS.color_bg_unnamed_5,
    },
    {
        title: ['Research and development,', 'Web solution.'],
        description:
            'V letu 2020 smo  uspešno kandidirali na razpisu SPIRITa Slovenija, javna agencija "SPODBUDA TRAJNOSTNE POSLOVNE STRATEŠKE TRANSFORMACIJE IN RAZVOJ NOVIH POSLOVNIH MODELOV V SLOVENSKIH PODJETJIH ZA LAŽJE VKLJUČEVANJE V GLOBALNE VERIGE VREDNOSTI" - Sklop A in Sklop B, in sicer v Sklopu B z namenom izvedbe projekta "Razvoj sistema za učenje (LR System)". \n' +
            '\n' +
            'Javni razpis je sofinanciran s pomočjo\n' +
            '\n' +
            '- Evropskega sklada za regionalni razvoj: www.eu-skladi.si,\n' +
            '- Ministrstva za gospodarski razvoj in tehnologijo: www.mgrt.gov.si ter\n' +
            '- SPIRIT Slovenija, javne agencije: www.spiritslovenia.si.\n' +
            '\n' +
            'Časovni okvir projekta: 1.4.2021 do 31.10.2021.\n',
        image: sideways,
        name: 'LR System.',
        nameColor: NAME_COLORS.color_unnamed_2,
        color: BACKGROUND_COLORS.color_bg_unnamed_6,
        more: 'https://www.podjetniski-portal.si/programi/trajnostna-poslovna-strateska-transformacija',
    },
    {
        title: ['Research and development,', 'Web solution.'],
        description:
            'V sklopu javnega razpisa “DEMO PILOTI II 2018” je sofinanciran projekt “Sistem za učinkovito in transparentno proizvodnjo”, “EcoTrail” v konzorcijskem konceptu. Namen izvedbe projekta je razviti in demonstrirati standardizirano informacijsko rešitev za vzpostavitev just-in-time proizvodnega okolja (JiT okolje) ter izkoristiti možnosti, ki jih nudijo blockchain tehnologija, IoT (Internet stvari) in obvladovanje velikih količin podatkov. \n' +
            '\n' +
            'Naložbo sofinancira RS in EU iz Evropskega sklada za regionalni razvoj (www.eu-skladi.si).' +
            '\n' +
            '\n Časovni okvir izvedbe:  3.1.2019 - 31.12.2021.',
        image: ecotrail,
        name: 'Ecotrail.',
        nameColor: NAME_COLORS.color_unnamed_1,
        color: BACKGROUND_COLORS.color_bg_unnamed_7,
        more: 'https://www.eu-skladi.si/',
    },
];

export const TAKK_SECTIONS = [
    {
        title: 'Custom subscription model solution',
        image: takk1,
    },
    {
        title: 'Custom-Built Product Categories',
        image: takk2,
    },
    {
        title: 'Tailored Checkout Process',
        image: takk3,
    },
    {
        title: 'Engaging Blog Layout',
        image: takk4,
    },
];
export const NEW_CASE_STUDIES = [
    {
        title: 'Case study',
        name: 'mBank@Net',
        technologies: ['Android', 'iOS'],
        color: STUDY_COLORS.color_1,
        description:
            'The mBank@net mobile banking app is a central digital channel for Nova KBM’s customers, where users can perform and monitor basic and complex services. These include easily transferring money between individuals by entering the amount and sharing the connection via SMS,  Viber, etc., making domestic and international payments, exchanging to foreign currencies, paying and monitoring e-bills, accounts, card transactions, managing loans, and much more. Additionally, it also allows opening up a bank account for new customers.',
        image: mbank,
        headline: 'Mobile development for Mind Candy Ltd.',
    },
    {
        title: 'Case study',
        name: 'mBank@Net',
        technologies: ['Android', 'iOS'],
        color: STUDY_COLORS.color_1,
        description:
            'The mBank@net mobile banking app is a central digital channel for Nova KBM’s customers, where users can perform and monitor basic and complex services. These include easily transferring money between individuals by entering the amount and sharing the connection via SMS,  Viber, etc., making domestic and international payments, exchanging to foreign currencies, paying and monitoring e-bills, accounts, card transactions, managing loans, and much more. Additionally, it also allows opening up a bank account for new customers.',
        image: test,
        headline: 'Mobile development for Mind Candy Ltd.',
    },
];
export const CLUTCH_STATS = {
    stars: 5.0,
    reviews: 21,
};

export const LUKA = {
    cover: luka_cover,
    name: 'Luka.',
    title: 'CEO',
    social: {
        facebook: 'https://facebook.com',
        twitter: 'https://twitter.com',
    },
};

export const META = {
    siteTitle: 'Equaleyes - Building the future of software with cutting-edge mobile, web, and blockchain solutions.', // <title>
    shortSiteTitle: 'Equaleyes - ', // <title> ending for posts and pages
    siteDescription:
        'Equaleyes Solutions is an award-winning software development company based in Ljubljana and Maribor, Slovenia. We create web and mobile apps that stand out.',
    siteUrl: 'https://equaleyes.com',
    // pathPrefix: "",
    siteImage: preview,
    siteLanguage: 'en',

    /* author */
    authorName: 'Raslav Milutinovic',
    authorTwitterAccount: 'rasovica',

    /* info */
    headerTitle: 'Equaleyes',
    headerSubTitle: 'Top notch development',

    gravatarImgMd5: '',

    // social
    authorSocialLinks: [
        { name: 'github', url: 'https://github.com/rasovica' },
        { name: 'twitter', url: 'https://twitter.com/rasovica' },
        { name: 'facebook', url: 'http://facebook.com/rasovica' },
    ],
};

export const webFrontend = ['React', 'Next.js', 'Angular', 'Gatsby', 'Webflow', 'Wordpress'];

export const webBackend = ['TypeScript', 'Python', 'C/C++', 'C#', '.NET', 'Java', 'Golang'];

export const designServices = [
    'Figma',
    'Figjam',
    'Adobe Photoshop',
    'Adobe Illustrator',
    'Adobe Premiere',
    'Adobe After Effects',
    'Keyshape',
];

export const mobileServices = ['iOS (Swift)', 'Android (Java, Kotlin)'];
