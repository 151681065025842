import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import 'bootstrap/dist/css/bootstrap.min.css';
import './layout.css';
import './fontello.css';

import { Footer } from '../footer';
import { Navbar } from '../navbar/navbar';

import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR, TITLE_FONT, SECONDARY_BG_COLOR, GRAYSCALE_100 } from '../../constants';
import { CookiesToast } from '../shared';
import Link from '../shared/link';
import { Container } from 'reactstrap';
import PageTransition from 'gatsby-plugin-page-transitions';
import { globalHistory } from '@reach/router';

const Wrapper = styled.div`
    position: relative;
`;

const MobileNavigation = ({ className, canScroll, setCanScroll }) => {
    const MobileNavigationContainer = styled.div`
        height: 100%;
        width: 100%;
        overflow: scroll;
        position: relative;
        background-color: ${SECONDARY_BG_COLOR};
    `;

    const ItemContainer = styled(Container)`
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        flex-grow: 1;
        padding-top: 15px;
        min-height: 80%;
    `;

    const Item = styled(Link)`
        font-family: ${TITLE_FONT};
        font-size: 22px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.2px;
        color: ${PRIMARY_TEXT_COLOR};
        text-decoration: none;
        position: relative;
        text-align: left;
        margin-left: 2em;
        margin-right: 2em;
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        border-bottom: 2px ${PRIMARY_TEXT_COLOR} solid;

        :hover {
            text-decoration: none;
        }

        &:last-of-type {
            border-bottom: none;
        }
    `;

    const Close = styled.div`
        text-align: right;
        font-size: 20px;
        font-weight: bold;
        margin-left: auto;
    `;

    const { enabled, setEnabled } = useContext(NavigationContext);
    const location = globalHistory.location.pathname;

    const closeNavbar = () => {
        setEnabled(!enabled);
        setCanScroll(!canScroll);
    };

    const closeNavbarAndRedirect = () => {
        setTimeout(() => {
            setCanScroll(!canScroll);
            setEnabled(!enabled);
        }, 500);
    };

    return (
        <MobileNavigationContainer className={className}>
            <ItemContainer className="animated-mobile-navbar">
                <Close
                    className="closeMenu"
                    onClick={() => {
                        closeNavbar();
                    }}
                >
                    <div id="animatedHamburgerMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </Close>

                <Item
                    to={'/'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Home
                </Item>

                <Item
                    to={'/about'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/about' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    About
                </Item>

                <Item
                    to={'/services'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/services' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Services
                </Item>

                <Item
                    to={'/case-studies'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/case-studies' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Case studies
                </Item>

                <Item
                    to={'/careers'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/careers' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Careers
                </Item>

                <Item
                    to={'/contact'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/contact' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Contact
                </Item>
            </ItemContainer>
        </MobileNavigationContainer>
    );
};

const ScrollToTop = () => {
    const ScrollToTopWrapper = styled.div`
        position: fixed;
        bottom: 24px;
        right: 48px;
        height: 56px;
        width: 56px;
        border-radius: 50%;
        background-color: ${ GRAYSCALE_100 };
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 90;

        @media (max-width: 768px) {
            right: 24px;
        }
    `;

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (<ScrollToTopWrapper onClick={() => {
        scrollToTop();
    }}>
        <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.514104 11.2779C0.271048 11.0348 0.271048 10.6946 0.514104 10.4515L10.6738 0.243161C10.9169 0.048717 11.2572 0.048717 11.5002 0.243161L21.6599 10.4515C21.903 10.6946 21.903 11.0348 21.6599 11.2779L20.7363 12.2501C20.4933 12.4446 20.1044 12.4446 19.9099 12.2501L11.1113 3.4515L2.2641 12.2501C2.06966 12.4446 1.68077 12.4446 1.43771 12.2501L0.514104 11.2779Z"
                fill="#011640" />
        </svg>
    </ScrollToTopWrapper>);
}

const Layout = ({
                    children,
                    theme,
                    showShadow,
                    withNavigation,
                    bgColor,
                    backToTop,
                    setCanScroll,
                    canScroll,
                    hideLogo,
                }) => {
    const [enabled, setEnabled] = useState(false);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const toggleVisibility = () => {
        if (window.scrollY > 0) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    return (
        <NavigationContext.Provider value={{ enabled, setEnabled }}>
            {enabled ? (
                <MobileNavigation setCanScroll={setCanScroll} canScroll={canScroll} />
            ) : withNavigation ? (
                <Wrapper>
                    <Navbar
                        theme={theme}
                        showShadow={showShadow}
                        bgColor={bgColor}
                        backToTop={backToTop}
                        setCanScroll={setCanScroll}
                        canScroll={canScroll}
                    />
                    <PageTransition>{children}</PageTransition>
                    <Footer />
                </Wrapper>
            ) : (
                children
            )}
            <CookiesToast />
            {
                showScrollToTop && <ScrollToTop />
            }
        </NavigationContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.string,
    withNavigation: PropTypes.bool,
};

Layout.defaultProps = {
    theme: 'light',
    withNavigation: true,
};

export const NavigationContext = React.createContext(false);
export default Layout;
