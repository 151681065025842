import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, ...other }) => {
    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const external = /(^tel:)|(^mailto:)|((https?)\:\/\/([\w-]+\.)?([\w-])+\.(\w)+\/?[\w\?\.\=\&\-\#\+\/]+)/.test(to); // eslint-disable-line
    // Use Gatsby Link for internal links, and <a> for others
    if (!external) {
        return to === undefined ? (
            children
        ) : (
            <GatsbyLink to={to} activeClassName={activeClassName} {...other} rel={"nofollow"}>
                {children}
            </GatsbyLink>
        );
    }
    return (
        <a href={to} {...other} target="_blank" rel="noopener noreferrer nofollow">
            {children}
        </a>
    );
};

export default Link;
